import React from 'react';
import Page from 'components/page';
import loadable from '@loadable/component';
import Footer from 'components/footer';

import { isAuthenticated } from 'services/authentication-service';

import { Typography } from '@material-ui/core';

import useStyles from './styles';

const LazyFaqBanner = loadable(() => import('components/banners/faq'));
const LazyFaqBannerAuth = loadable(() => import('components/banners/in-app-banners/faq'));
const LazyMessenger = loadable(() => import('components/facebook-messenger'));
const MenAtWork = loadable(() => import('components/men-at-work'));

const SupportNavigationPage = () => {
  const styles = useStyles();

  const authenticated = isAuthenticated();

  return (
        <Page title="Support" description={'Get Support or help using Total Loyalty'}>
          {authenticated ? <LazyFaqBannerAuth/> : <LazyFaqBanner/>}
          <Typography variant="h2" component="h1" className={styles.heading} align="center">
            Frequently Asked Questions
          </Typography>
          <MenAtWork useTitle={false}/>
          <LazyMessenger/>
          <Footer />
        </Page>
  );
};

export default SupportNavigationPage;
